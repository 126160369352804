
//import SC from "soundcloudSdk";
/*soundManager.setup({
		url: '/js/libs/soundmanager2/swf',
		flashVersion: 9, 
		preferFlash: false,
		debugMode: false,
		ignoreMobileRestrictions: true,
		forceUseGlobalHTML5Audio: false,
		useHTML5Audio: true,
		onready: function() {
			//console.log("soundmanager is ready");
		}
	});


	TrebbleWindowHistoryTracker = {};*/

  const httpOrHttpsPref = (location.protocol == 'https:')?"https://": "http://";
	window.SC.initialize({
		client_id: 'e1ddc5a48279cd6d15c35595c26c7b05'
	});
	window.SC._baseUrl = httpOrHttpsPref+"connect.soundcloud.com";

	
